import React from 'react';
import Select from 'components/util/Select';

const EditorSidebarVisibilitySelect = ({
    value,
    options,
    canEdit,
    onChange,
    translate,
    selectedOption,
}) => (
    <div className="form__group">
        <label className="label" htmlFor="visibility">
            {translate('editor.form.visibility.label')}
        </label>
        {canEdit ? (
            <Select
                inputId="visibility"
                clearable={false}
                value={value}
                placeholder={translate('editor.form.visibility.placeholder')}
                options={options}
                onChange={onChange}
            />
        ) : (
            <div>{selectedOption.label}</div>
        )}
    </div>
);

export default EditorSidebarVisibilitySelect;
