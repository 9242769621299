import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import merge from 'lodash.merge';
import {setActiveLanguage, getLanguages} from 'react-localize-redux';
import actions from 'store/actions';
import {checkLangHelper} from 'lib/helpers/locale';
import {scopeSentryUser} from 'lib/helpers/scopeUser';
import {brandingDefaults, parseColors} from 'lib/styles/colors';
import getUserRoleHelper from 'util/getUserRoleHelper';
import LoadingWrapper from 'lib/components/LoadingWrapper';
import Notifications from 'lib/components/Notifications';
import LangMenu from 'lib/components/LangMenu';
import Routes from 'components/Routes/Routes';
import Modals from 'components/app/Modals';
import GlobalStyles from 'styles/index';
import AppWithProviders from './AppWithProviders';
// Global lib css imports
import 'react-input-range/lib/css/index.css';
import 'react-phone-input-2/lib/style.css';
import 'react-tippy/dist/tippy.css';

// Admin only css imports
import 'react-datetime/css/react-datetime.css';

const AppWithState = () => {
    const company = useSelector(state => state.company.data);
    const branding = useSelector(state => state.company.data.branding);

    const isAuthenticated = useSelector(
        state => state.auth.accessToken !== null,
    );
    const me = useSelector(state => state.users.byId[state.users.me]);
    const languages = useSelector(state => getLanguages(state.localize));
    const dispatch = useDispatch();
    const fetchCompany = forAuthenticated =>
        dispatch(actions.company.fetchCompany(forAuthenticated));
    const fetchAppDomains = () =>
        dispatch(actions.appDomains.fetchAppDomains());
    const fetchSmartTags = () => dispatch(actions.smartTags.fetchSmartTags());
    const setLanguage = language => dispatch(setActiveLanguage(language));
    const fetchMe = () => dispatch(actions.users.me.fetchMe());
    const fetchAppHealth = () =>
        dispatch(actions.appHealth.fetchAppHealth('admin'));

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Set language
    useEffect(() => {
        if (me?.language) {
            if (checkLangHelper(me.language, languages)) {
                setLanguage(me.language);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me?.language]);

    // Setup Sentry and Intercom
    useEffect(() => {
        if (me) {
            scopeSentryUser(me);
        }

        if (me && typeof window.Intercom === 'function') {
            const intercomSettings = {
                email: me.work_email || me.email,
                name: `${me.first_name} ${me.last_name}`,
                user_id: me.id,
                company: {
                    id: company.slug,
                    name: company.name,
                },
            };

            if (me.language && checkLangHelper(me.language, languages)) {
                intercomSettings.language_override = me.language;
            }

            window.Intercom('update', intercomSettings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]);

    const fetchInitialData = async () => {
        // TODO: Refactor this to something more like a story.
        setIsLoading(true);

        try {
            // First fetch the app health
            const {payload} = await fetchAppHealth();
            if (!payload.data.available) {
                return; // Stop fetching when app is not available
            }

            // Fetch other initial data
            const promises = [fetchCompany(isAuthenticated)];
            if (isAuthenticated) {
                promises.push(fetchAppDomains());
                promises.push(fetchSmartTags());
                promises.push(fetchMe());
            }

            await Promise.all(promises);
            setIsLoading(false);
        } catch (thrownError) {
            setError(thrownError);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        (async () => fetchInitialData())();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const userRole = getUserRoleHelper(me);
    const parsedBranding = merge(
        {},
        brandingDefaults,
        parseColors(branding.colors || brandingDefaults),
    );
    return (
        <AppWithProviders branding={parsedBranding} error={error}>
            <LoadingWrapper loading={isLoading}>
                {!isAuthenticated && <LangMenu />}

                <Routes isAuthenticated={isAuthenticated} userRole={userRole} />

                <Modals />
                <Notifications />
            </LoadingWrapper>
            <GlobalStyles />

            <style jsx>
                {`
                    :global(.app > .loader) {
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                `}
            </style>
        </AppWithProviders>
    );
};

export default AppWithState;
