import React from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter, Route} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import BrandingProvider from 'lib/components/providers/BrandingProvider';
import AppErrorBoundary from 'components/app/AppErrorBoundary';
import HealthWrapper from 'components/app/HealthWrapper';
import {ThemeProvider} from 'styled-components';
import * as theme from 'styles/theme';
import * as media from 'lib/styles/media';
import {AdminInboxProvider} from 'modules/AdminInbox/adminInbox.data';

const AppWithProviders = ({error, branding, children}) => {
    const brandingMerged = {
        branding,
        ...theme,
        ...media,
        ...branding,
    };
    return (
        <AppErrorBoundary error={error}>
            <HealthWrapper>
                <BrowserRouter>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <ThemeProvider theme={brandingMerged}>
                            <BrandingProvider value={brandingMerged}>
                                <AdminInboxProvider>
                                    {children}
                                </AdminInboxProvider>
                            </BrandingProvider>
                        </ThemeProvider>
                    </QueryParamProvider>
                </BrowserRouter>
            </HealthWrapper>
        </AppErrorBoundary>
    );
};

AppWithProviders.propTypes = {
    error: PropTypes.shape({}),
    branding: PropTypes.shape(),
    children: PropTypes.node.isRequired,
};
AppWithProviders.defaultProps = {
    error: null,
    branding: {},
};

export default AppWithProviders;
